import React from 'react'
import Layout from 'layouts/default'
import styled from '@emotion/styled'
import { BlockTitle, Container } from 'components/common/styles'
import ListItem from 'components/pages/blog/list-item'
import HeroItem from 'components/pages/blog/hero-item'
import { graphql } from 'gatsby'

type Props = {
  data: {
    allContentfulBlogPost: {
      edges: [
        {
          node: {
            contentful_id: string
            title: string
            slug: string
            publishDate: string
            heroImage: {
              fixed: {
                src: string
              }
              description: string
            }
            description: {
              childMarkdownRemark: {
                html: string
              }
            }
          }
        }
      ]
    }
  }
}

const Title = styled(BlockTitle)`
  margin-top: 55px;
  margin-bottom: 50px;
`
const Items = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  
  margin-top: 90px;
  
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const Item = styled(ListItem)`
  width: 30%;
  margin-bottom: 90px;
  
  &:nth-of-type(3n+3),
  &:nth-of-type(3n+2) {
    margin-left: 5%;
  }
  
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 40px;
    
    &:nth-of-type(3n+3),
    &:nth-of-type(3n+2) {
      margin-left: 0;
    }
  }
`

const BlogPage: React.FC<Props> = props => {
  React.useEffect(() => {
    window.analytics.page('Blog')
  }, [])
  return (
    <Layout title='Blog | ComCard'>

      <Container>
        <Title>Blog</Title>
      </Container>
      <HeroItem
        data={{
          title: props.data.allContentfulBlogPost.edges[0].node.title,
          intro: props.data.allContentfulBlogPost.edges[0].node.description.childMarkdownRemark.html,
          slug: props.data.allContentfulBlogPost.edges[0].node.slug,
          date: props.data.allContentfulBlogPost.edges[0].node.publishDate,
          image: props.data.allContentfulBlogPost.edges[0].node.heroImage.fixed.src,
          imageAlt: props.data.allContentfulBlogPost.edges[0].node.heroImage.description,
        }}
      />

      <Container>
        <Items>
          {props.data.allContentfulBlogPost.edges.slice(1).map(i => (
            <Item
              key={i.node.contentful_id}
              data={{
                title: i.node.title,
                slug: i.node.slug,
                date: i.node.publishDate,
                image: i.node.heroImage.fixed.src,
                imageAlt: i.node.heroImage.description,
              }} />
          ))}
        </Items>
      </Container>
    </Layout>
  )
}

export const query = graphql`
    {
        allContentfulBlogPost(sort: {fields: publishDate, order: DESC}, limit: 6) {
            edges {
                node {
                    contentful_id
                    slug
                    title
                    publishDate(formatString: "MMMM DD, YYYY")
                    heroImage {
                        description
                        fixed(height:  800, width: 1070, quality: 70, toFormat: JPG) {
                            src
                        }
                    }
                    description {
                        childMarkdownRemark {
                            html
                        }
                    }
                }
            }
        }
    }
`

export default BlogPage
