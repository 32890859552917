import * as React from 'react'
import styled from '@emotion/styled'
import { Container } from 'components/common/styles'
import ReadMoreLink from '../../common/read-more-link'
import { Link } from 'gatsby'
import { between } from 'polished'

type Props = {
  data: {
    title: string
    intro: string
    date: string
    slug: string
    image: string
    imageAlt: string
  }
};

const AdaptiveContainer = styled(Container)`
  flex-direction: row;
  align-items: center;
  
  @media screen and (max-width: 768px) {
    padding: 0 17px;
    flex-direction: column;
  }
`
const ImageLink = styled(Link)`
  max-width: 535px;
  width: 50%;
  
  @media screen and (max-width: 768px) {
    max-width: none;
    width: 100%;
    margin-bottom: 20px;
  }
`

const Image = styled.img`
  width: 100%;
  border-radius: 60px;
`
const Info = styled.div`
  width: 50%;
  margin-left: 140px;
  
  @media screen and (max-width: 1300px) {
    margin-left: ${between('30px', '140px', '768px', '1300px')};
  }
  
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-left: 0;
  }
`
const Date = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: #666666;
  
  margin-bottom: 15px;
`
const Title = styled.h2`
  font-size: 34px;
  line-height: 1.5;
  font-weight: bold;
  color: #000;
  
  margin-bottom: 25px;
`
const Intro = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: #666666;
  margin-bottom: 25px;
`


const HeroItem: React.FC<Props> = props => {
  const { data: { title, intro, date, slug, image, imageAlt } } = props
  return (
    <AdaptiveContainer>
      <ImageLink to={`/blog/${slug}`}>
        <Image src={image} alt={imageAlt} />
      </ImageLink>
      <Info>
        <Date>{date}</Date>
        <Title>{title}</Title>
        <Intro dangerouslySetInnerHTML={{ __html: intro }} />
        <ReadMoreLink to={`/blog/${slug}`} />
      </Info>
    </AdaptiveContainer>
  )
}

export default HeroItem
