import * as React from 'react'
import ReadMoreLink from '../../common/read-more-link'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

type Props = {
  data: {
    title: string
    date: string
    slug: string
    image: string
    imageAlt: string
  }
};

const Item = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-decoration: none;
  
  &:hover {
    img {
      opacity: 0.8;
    }
    
    a {
      img {
        opacity: 1;
        margin-left: 8px;
      }
    }
  }
`
const ImageContainer = styled.div`
  align-self: stretch;
  margin-bottom: 25px;
`
const Image = styled.img`
  width: 100%;
  min-width: 100%;
  border-radius: 20px;
  
  transition: opacity ease-in-out 100ms;
`
const Date = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: #666666;
  
  margin-bottom: 15px;
`
const Title = styled.div`
  font-size: 20px;
  line-height: 1.4;
  font-weight: 600;
  color: #000;
  
  margin-bottom: 15px;
`

const ListItem: React.FC<Props> = ({ data: { image, imageAlt, title, slug, date }, ...props }) => {
  return (
    <Item to={`/blog/${slug}`} {...props}>
      <ImageContainer>
        <Image src={image} alt={imageAlt} />
      </ImageContainer>
      <Date>{date}</Date>
      <Title>{title}</Title>
      <ReadMoreLink to={`/blog/${slug}`} />
    </Item>
  )
}

export default ListItem
