import * as React from 'react'
import { GatsbyLinkProps } from 'gatsby-link'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

import Arrow from 'assets/images/blog/read_more_chevron.svg'

type Props = {} & GatsbyLinkProps<{}>;

const ReadLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  font-size: 16px;
  line-height: 1.5;
  font-weight: bold;
  color: ${props => props.theme.colors.green100};
  text-decoration: none;
  
  img {
    margin-left: 6px;
    transition: margin-left ease-in-out 100ms;
  }
  
  &:hover {
    img {
      margin-left: 10px;
    }
  }
`

const ReadMoreLink: React.FC<Props> = props => {
  return (
    <ReadLink className="readMore" {...props}>
      Read more
      <img src={Arrow} />
    </ReadLink>
  )
}

export default ReadMoreLink
